.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  /* background-color: #5d5d5d; */
  min-height: 100vh;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  /* font-size: 18px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  font-size: 20px;
  padding: 25px;
  text-align: left;
  line-height: 1.4em;
}

.centered {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.footer {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}